@import url("https://fonts.googleapis.com/css?family=Krub:400,500,600,700");
@import url("https://fonts.googleapis.com/css2?family=Source+Serif+Pro:ital,wght@0,200;1,200;1,700&&display=swap");

@mixin base-circle {
  border-radius: 50%;
  display: flex;
  align-items: center;
  justify-content: center;
  transition: 0.3s;
}

@mixin circle-hover {
  background-color: rgba(255, 255, 255, 0.068);
  border: 1px solid transparent;
}

body {
  background-color: #000000;
  margin: 0;
  font-family: "Krub", sans-serif;
  font-size: 16px;
  width: 100vw;
}

#author-wrapper {
  position: absolute;
  top: 89%;
  left: 2%;
}

.wrapper {
  position: absolute;
  display: grid;
  grid-template-columns: 1fr 1fr;
  justify-content: center;
  align-items: center;
  width: 100%;
  height: 100vh;
}

.circle {
  @include base-circle;
  width: 100vh;
  height: 100vh;
}

.programming-circle {
  animation: float 6s infinite alternate;
  justify-self: end;
  margin-left: 30px;
}

.second-programming-circle {
  position: absolute;
  top: 0;
  margin-left: 0;
  border: 1px solid rgba(255, 255, 255, 0.24);

  &:hover {
    @include circle-hover;
  }
}

.design-circle {
  top: 5%;
  left: 90%;
  animation: float 3s infinite alternate;
  border: 1px solid rgba(255, 255, 255, 0.24);
  position: absolute;
}

.outer-circle {
  @include base-circle;
  width: 65vh;
  height: 65vh;
  border: 1px solid rgba(255, 255, 255, 0.322);

  &:hover {
    @include circle-hover;
  }
}

.back-circle {
  margin-bottom: 20px;
  margin-left: 0;
  border: 1px solid rgba(255, 255, 255, 0.151);
  animation: float-back 10s infinite alternate;
  z-index: -2;
}

.inner-circle {
  @include base-circle;
  width: 35vh;
  height: 35vh;
  font-size: 20px;
  border: 1px solid rgba(255, 255, 255, 0.459);
  color: rgba(255, 255, 255, 0.199);
  letter-spacing: 2px;
  font-family: "Source Serif Pro", serif;
  font-size: 1.5rem;
  font-weight: 200;
  font-style: italic;
  text-decoration: none;

  &:hover {
    background-color: rgb(16, 214, 198);
    border: 1px solid transparent;
    color: black;
    cursor: pointer;
    transition: 0.6s;
    animation: glow 1s infinite alternate;
  }
}

.outter-prog-animation {
  animation: float 6s infinite alternate, glow 3s infinite alternate;
}

.outter-design-animation {
  animation: float 3s infinite alternate, glow 3s infinite alternate;
}

.inner-glow {
  animation: glow 2s infinite alternate;
}

.hoverEffect {
  &:hover {
    @include circle-hover;
  }
}

@keyframes glow {
  from {
    box-shadow: 0 0 1px -1px rgba(255, 255, 255, 0.39);
  }
  to {
    box-shadow: 0 0 10px 1px rgba(255, 255, 255, 0.226);
  }
}

@keyframes float {
  0% {
    transform: translatey(0px);
  }
  50% {
    transform: translatey(-10px);
  }
  100% {
    transform: translatey(0px);
  }
}

@keyframes float-back {
  0% {
    transform: translatey(0px) translatex(0px);
  }
  50% {
    transform: translatey(-10px) translatex(-10px);
  }
  100% {
    transform: translatey(0px) translatex(0px);
  }
}

.author {
  position: absolute;
  font-size: 1.1rem;
  color: rgba(181, 181, 181, 0.651);
  font-weight: 600;
  line-height: 1.1;
  opacity: 0.6;
}

@media only screen and (max-width: 1000px) {
  .circle {
    width: 70vh;
    height: 70vh;
  }

  .outer-circle {
    width: 45vh;
    height: 45vh;
  }

  .inner-circle {
    width: 20vh;
    height: 20vh;
  }
}

@media only screen and (max-width: 768px) {
  .circle {
    width: 60vh;
    height: 60vh;
  }

  .outer-circle {
    width: 35vh;
    height: 35vh;
  }

  .inner-circle {
    width: 15vh;
    height: 15vh;
  }

  .inner-circle {
    font-size: 1.3rem;
  }
}

@media only screen and (max-width: 600px) {
  #author-wrapper {
    top: 3%;
    left: 7%;
  }

  .author {
    font-size: 1.8rem;
  }

  .wrapper {
    grid-template-columns: 100%;
    height: 70vh;
  }

  .design-circle {
    top: 90%;
    left: 0;
  }

  .circle {
    width: 40vh;
    height: 40vh;
  }

  .outer-circle {
    width: 25vh;
    height: 25vh;
  }

  .inner-circle {
    width: 10vh;
    height: 10vh;
  }

  .programming-circle {
    margin-left: 0;
    justify-self: center;
  }

  .inner-circle {
    font-size: 1rem;
    color: #fff;
  }
}
